<template>
  <div class="bg-white">
    <div class="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
      <div class="lg:grid lg:grid-cols-3 lg:gap-8">
        <div>
          <h2 class="text-3xl font-bold tracking-tight text-gray-900">Can't find what you're looking for?</h2>
          <p class="mt-4 text-lg text-gray-500">Reach out to our <a href="mailto:support@complaia.com" class="font-medium text-indigo-600 hover:text-indigo-500">customer support</a> team.</p>
        </div>
        <div class="mt-12 lg:col-span-2 lg:mt-0">
          <dl class="space-y-12">
            <div>
              <dd class="mt-2 text-base text-gray-500">
                Thank you for purchasing our service plan at https://complaia.com.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                In line with providing our customers with the best quality and client experience, we have established a considerate refund policy.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                We deal in digital goods and services. Complaia has a NO REFUND policy once you purchase our service. All sales are final. There are no refunds. You can email us at hello@complaia.com for more inquiries related to refunds.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                Purchaser is solely responsible for:
                <ol class="list-decimal list-inside mt-2">
                  <li>Choices made regarding the course/product purchased.</li>
                  <li>Not allowing children or other unauthorized family members or friends to access your credit cards or your account at the payment site to ensure that no one pays for a purchase without your express permission.</li>
                </ol>
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                SUBSCRIPTIONS
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                Some parts of the Service are billed on a subscription basis ("Subscription(s)"). You will be billed in advance on a recurring and periodic basis ("Billing Cycle").
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions unless you cancel it or Complaia.com cancels it. You may cancel your Subscription renewal either through your online account management page or by contacting Complaia.com’s customer support team.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                A valid payment method, including credit card or PayPal, is required to process the payment for your Subscription. You shall provide Complaia.com with accurate and complete billing information including full name, address, state, zip code, telephone number, and valid payment method information. By submitting such payment information, you automatically authorize Complaia.com to charge all Subscription fees incurred through your account to any such payment instruments.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                Should automatic billing fail to occur for any reason, Complaia.com will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice.
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                FEE CHANGES
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                Complaia.com, in its sole discretion and at any time, may modify the Subscription fees for the Subscriptions. Any Subscription fee change will become effective at the end of the then-current Billing Cycle.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                Complaia.com will provide you with reasonable prior notice of any change in Subscription fees to give you an opportunity to terminate your Subscription before such change becomes effective.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                Your continued use of the Service after the Subscription fee change comes into effect constitutes your agreement to pay the modified Subscription fee amount.
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                REFUNDS
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                Since we deal in digital goods and services, that’s why we do not offer refunds after any purchase. We request you to get all the details before purchasing that available on our website. Still, if you have any doubts related to the services. Please feel free to contact us at hello@complaia.com.
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                SALE ITEMS (IF APPLICABLE)
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                Unfortunately, sale items cannot be refunded.
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                GIFTS
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                As of now, we don't have any feature of gifting or promo codes.
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                SHIPPING
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                Everything is online. No need for any physical shipping.
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                DOUBLE PURCHASE
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                In case of double payment, due to any reason, you will get your 100% money back. Usually, our system automatically detects such transactions and refunds them. But if due to any reason mentioned actions are not triggered, please us out at hello@complaia.com.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                We keep our pricing very transparent and honest.
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  }
}

</script>
